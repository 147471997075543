'use strict';
const Promise = require('bluebird');
const superagent = require('superagent');

const NasEmailEventsActions = {
  sendMailgunEmail: function (params) {
    return new Promise(function (resolve, reject) {
      superagent.post('/api/nas_email_events/send_mailgun_email')
        .send(params)
        .end((err, res) => {
          if (err) {
            resolve(err)
            return
          }
          resolve(res.body);
        })
    })
  }
};

module.exports = NasEmailEventsActions;