'use strict';
const Promise = require('bluebird');
const superagent = require('superagent');

const StripeActions = {
  getReservationStripeSession:function(params) {
    return new Promise(function(resolve, reject) {
      superagent.get('/api/stripe/get_reservation_stripe_session').query(params).end((err, res) => {
        if(err) {
          reject(err)
        }
        resolve(res.body.stripe_session)
      })
    })
  },
  publicCheckout: function (params) {
    return new Promise(function (resolve , reject) {
      superagent.post('/public_api/stripe/public_checkout').send(params).end((err, res) => {
        if(err) {
          reject(err);
          return 
        }
        resolve(res)
      })
    })
  },
  createCheckoutSession: function (params) {
    return new Promise(function (resolve, reject) {
      superagent.post('/api/stripe/create_checkout_session').send(params).end((err, res) => {
        if (err) {
          reject(err);
          return
        }
        resolve(res.body.stripe_session)
      })
    })
  },
  getProducts: function (params = {}) {
    return new Promise(function (resolve, reject) {
      superagent.get('/api/stripe/get_products').query(params).end((err, res) => {
        if (err) { reject(err); }
        resolve(res.body.stripe_products);
      });
    });
  },
  createCustomer: function (params) {
    // Requires params.guest_id
    return new Promise(function (resolve, reject) {
      superagent.post('/api/stripe/create_customer').send(params).end((err, res) => {
        if (err) {
          reject(err);
          return
        }
        resolve(res.body)
      })
    })
  },
  updateCustomer: function (params) {
    return new Promise(function (resolve, reject) {
      superagent.put(`/api/stripe/update_customer`)
        .send(params)
        .end((err, res) => {
          if (err) { reject(err); }
          resolve(res.body.stripe_customer);
        })
    })
  },
  getCustomerPaymentMethods: function (params = {}) {
    return new Promise(function (resolve, reject) {
      superagent.get('/api/stripe/get_customer_payment_methods').query(params).end((err, res) => {
        if (err) { reject(err); }
        resolve(res.body.stripe_payment_methods);
      });
    });
  },
  createInvoice: function (params) {
    return new Promise(function (resolve, reject) {
      superagent.post('/api/stripe/create_invoice').send(params).end((err, res) => {
        if (err) {
          reject(err);
          return
        }
        resolve(res.body.stripe_invoice)
      })
    })
  },
  updateInvoice: function (params) {
    return new Promise(function (resolve, reject) {
      superagent.put('/api/stripe/update_invoice').send(params).end((err, res) => {
        if (err) {
          reject(err);
          return
        }
        resolve(res.body.stripe_invoice)
      })
    })
  },
  getInvoice: function (params) {
    return new Promise(function (resolve, reject) {
      superagent.get('/api/stripe/get_invoice').query(params).end((err, res) => {
        if (err) {
          reject(err);
          return
        }
        resolve(res.body.stripe_invoice)
      })
    })
  },
  finalizeInvoice: function (params) {
    return new Promise(function (resolve, reject) {
      superagent.put('/api/stripe/finalize_invoice').send(params).end((err, res) => {
        if (err) {
          reject(err);
          return
        }
        resolve(res.body.stripe_invoice)
      })
    })
  },
  sendInvoice: function (params) {
    return new Promise(function (resolve, reject) {
      superagent.put('/api/stripe/send_invoice').send(params).end((err, res) => {
        if (err) {
          reject(err);
          return
        }
        resolve(res.body.stripe_invoice)
      })
    })
  },
  payInvoice: function (params) {
    return new Promise(function (resolve, reject) {
      superagent.put('/api/stripe/pay_invoice').send(params).end((err, res) => {
        if (err) {
          reject(err);
          return
        }
        resolve(res.body.stripe_invoice)
      })
    })
  },
  voidInvoice: function (params) {
    return new Promise(function (resolve, reject) {
      superagent.put('/api/stripe/void_invoice').send(params).end((err, res) => {
        if (err) {
          reject(err);
          return
        }
        resolve(res.body.stripe_invoice)
      })
    })
  },
};

module.exports = StripeActions;