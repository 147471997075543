const React = require('react');
const { styled} = require('@mui/material/styles');
const { Route, Switch } = require('react-router-dom');
const CssBaseline = require('@mui/material/CssBaseline').default;
const MuiDrawer = require('@mui/material/Drawer').default;
const Box = require('@mui/material/Box').default;
const MuiAppBar = require('@mui/material/AppBar').default;
const Toolbar = require('@mui/material/Toolbar').default;
const List = require('@mui/material/List').default;
const Typography = require('@mui/material/Typography').default;
const Divider = require('@mui/material/Divider').default;
const IconButton = require('@mui/material/IconButton').default;
const Badge = require('@mui/material/Badge').default;
const Container = require('@mui/material/Container').default;
const MenuIcon = require('@mui/icons-material/Menu').default;
const ChevronLeftIcon = require('@mui/icons-material/ChevronLeft').default;
const NotificationsIcon = require('@mui/icons-material/Notifications').default;
const Copyright = require('./Copyright');
const ListItemButton = require('@mui/material/ListItemButton').default;
const ListItemIcon = require('@mui/material/ListItemIcon').default;
const ListItemText = require('@mui/material/ListItemText').default;
const HomeIcon = require('@mui/icons-material/Home').default;
const CheckAvailableRoomTypes = require('./CheckAvailableRoomTypes');
const AvailableRoomType = require('./AvailableRoomType');
const ThankYouPage = require('./ThankYouPage');
const ReservationSummary = require('./ReservationSummary');
class ClientLayout extends React.Component {
  constructor(props){
    super(props);
  }

  render(){
    return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Container maxWidth="lg" sx={{ mb: 4 }}>
              <Switch>
                <Route path={`${this.props.match.url}/:id/reservation_summary`} render={() => <ReservationSummary />} />
                <Route path={`${this.props.match.url}/thank_you`} render={() => <ThankYouPage />} />
                <Route path={`${this.props.match.url}/rooms/:id`} render={() => <AvailableRoomType />} />
                <Route path={`${this.props.match.url}/rooms`} render={() => <CheckAvailableRoomTypes />} />
                <Route path={`${this.props.match.url}`} render={() => <CheckAvailableRoomTypes />} />
              </Switch>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    );
  }
}

module.exports = ClientLayout;