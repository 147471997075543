'use strict'
var React = require('react');
const DoorFrontOutlinedIcon = require('@mui/icons-material/DoorFrontOutlined').default;
const roomData = 
  {
    name:'room1',
    short_description:'Donec pulvinar tortor lacus, sit amet imperdiet ipsum faucibus eu. Aliquam aliquam iaculis mauris, eget imperdiet orci blandit ac. Integer ultrices sed risus ut finibus.',
    coverPage:'https://www.gannett-cdn.com/-mm-/05b227ad5b8ad4e9dcb53af4f31d7fbdb7fa901b/c=0-64-2119-1259/local/-/media/USATODAY/USATODAY/2014/08/13/1407953244000-177513283.jpg,https://images.unsplash.com/photo-1566073771259-6a8506099945?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8aG90ZWxzfGVufDB8fDB8fA%3D%3D&w=1000&q=80,https://thumbs.dreamstime.com/b/hotel-room-13225019.jpg,https://ucarecdn.com/4f78bee9-11f0-4926-b1cf-f2709191da4b/,https://static01.nyt.com/images/2019/03/24/travel/24trending-shophotels1/24trending-shophotels1-videoSixteenByNineJumbo1600.jpg',
    imgPlaceHolder:'Spring Haven Motel',
    amenities:[
      {
        id:1,
        icon:<DoorFrontOutlinedIcon/>,
        title:'title',
        description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas orci libero, tempus sed faucibus vitae, blandit nec enim. Fusce ac volutpat elit, in faucibus diam. '
      },
      {
        id:2,
        icon:<DoorFrontOutlinedIcon/>,
        title:'title',
        description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas orci libero, tempus sed faucibus vitae, blandit nec enim. Fusce ac volutpat elit, in faucibus diam. '
      },
      {
        id:3,
        icon:<DoorFrontOutlinedIcon/>,
        title:'title',
        description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas orci libero, tempus sed faucibus vitae, blandit nec enim. Fusce ac volutpat elit, in faucibus diam. '
      },
    ],
    extendedDescription:'Phasellus lobortis ultrices nulla, eu hendrerit est vulputate porttitor. Sed dui eros, lobortis at massa eget, feugiat sodales nisl. Vestibulum nunc ex, vestibulum quis mauris ac, molestie cursus elit. Aliquam fringilla et leo eget consectetur. Aenean aliquam tellus vitae justo ultrices, quis euismod metus varius. Pellentesque lacinia eleifend lacus at tempus. Suspendisse suscipit porttitor massa, at porta mauris egestas sit amet. Mauris sagittis nisl quis auctor tristique. Duis id neque at risus facilisis tincidunt. Sed aliquet et enim eget suscipit. Phasellus a aliquet leo. Sed tincidunt lacinia justo in interdum. Cras mi metus, convallis id lacus vel, mollis tempus diam. Ut quis magna risus. In hac habitasse platea dictumst. Pellentesque nibh ex, faucibus a vehicula non, consectetur vitae quam.'
  }

const AvailableRoomHelpers = {
  ROOM_DATA:roomData
}

module.exports = AvailableRoomHelpers;