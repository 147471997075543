'use strict';

const UserReducer = function (state = [], action) {
  switch (action.type) {
    case 'rooms.load':
      return action.rooms || [];
    default:
      return state;
  }
};

module.exports = UserReducer;