'use strict';
const React = require('react');
const Box = require('@mui/material/Box').default;
const Typography = require('@mui/material/Typography').default;
const Container = require('@mui/material/Container').default;
const Paper = require('@mui/material/Paper').default;
const Grid = require('@mui/material/Grid').default;
const { styled } = require('@mui/material/styles');
const Divider = require('@mui/material/Divider').default;
const ListItemText = require('@mui/material/ListItemText').default;
const { Button } = require('@mui/material');
const TextField = require('@mui/material/TextField').default;
const { withRouter } = require('react-router-dom');
const StripeActions = require('../actions/StripeActions');
const { dispatchLoad } = require('../helpers/DispatchHelpers');
const { connect } = require('react-redux');
const { ROOM_CATEGORY } = require('../helpers/CheckAvailableRoomTypesHelpers');
const RoomsActions = require('../actions/RoomsActions');
const ReservationsActions = require('../actions/ReservationsActions');

const mapStateToProps = function (state, ownProps) {
  return {
    guest: state.guest,
    reservation: state.reservation
  }
}

const mapDispatchToProps = function (dispatch) {
  return {
    updateGuest: dispatchLoad(dispatch, 'guest'),
    updateReservation: dispatchLoad(dispatch, 'reservation')
  }
}

var todaysDate = new Date()
todaysDate.toISOString().split('T')[0]

var tomorrowsDate = new Date()
tomorrowsDate.setDate(todaysDate.getDate() + 1)

class ReservationSummary extends React.Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.myRef = React.createRef(null);

    let formattedArrivalDate = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    }).format(new Date(this.props.reservation.arrive_at));

    let formattedDepartureDate = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    }).format(new Date(this.props.reservation.depart_at));

    let arrive_at = new Date(this.props.reservation.arrive_at);
    let depart_at = new Date(this.props.reservation.depart_at);
    let timeDiff = Math.abs(depart_at.getTime() - arrive_at.getTime());
    let numOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24));

    this.state = {
      arrive_at:formattedArrivalDate,
      depart_at:formattedDepartureDate,
      guest:this.props.reservation.guestCategory,
      guestCount:this.props.reservation.guestCount,
      first_name:'',
      last_name:'',
      email:'',
      phone:0,
      employer:'',
      job:'',
      roomTypeId:0,
      roomTypeName:'',
      price:0,
      numOfNights:numOfNights,
      availableRoom:[],
      checkOutresponse:[],
      anchorRef:this.myRef,
      isLoading: false,
    }
  }

  componentDidMount() {
    function formatDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;
      return [year, month, day].join('-');
    }

    let params  = {
      id:Number(this.props.match.params.id)
    }
    let result = ROOM_CATEGORY.find((obj) => obj.id === params.id)

    this.setState({
      roomTypeId:result.id,
      roomTypeName:result.room_name,
      price:result.price,
    })
    
    let identifier = {
      identifier:result.code
    }
    // fetch rooms that has the room_code_category
    RoomsActions.wherePublic(identifier).then(result => {
      result.map(item => {
        let params = {
          room_id:item.id,
          arrive_at:formatDate(this.state.arrive_at),
          depart_at:formatDate(this.state.depart_at),
          useRangeFilter: true
        }
        // check if room has no reservation
        ReservationsActions.checkRoomAvailability(params).then(result => {
          if(result.length === 0) {
            // will be  added to the list of available rooms
            this.setState({
              availableRoom:[...this.state.availableRoom, item],
            })
          }
        })
      })
    })
  }


  handleSubmit(ref) {
    if(!ref) {
      return alert('Kindly fill out required information.')
    }

    // this should be on helpers
    function formatDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;
      return [year, month, day].join('-');
    }

    let checkoutParams = {
      first_name:this.state.first_name,
      last_name:this.state.last_name,
      phone:this.state.phone,
      email:this.state.email,
      employer:this.state.employer,
      job:this.state.job,
      arrive_at:formatDate(this.props.reservation.arrive_at),
      depart_at:formatDate(this.props.reservation.depart_at),
      guestCategory:this.props.reservation.guestCategory,
      guestCount:this.props.reservation.guestCount,
      roomTypeId:this.state.roomTypeId,
      roomTypeName:this.state.roomTypeName,
      price:this.state.price,
      availableRoom:this.state.availableRoom[0],
      numOfNights:this.state.numOfNights
    }

    StripeActions.publicCheckout(checkoutParams).then(result => {
      window.location.replace(result.body.stripe_session.url)
    })
  }

  handleOnChange(e) {
    this.setState({
      [e.target.name]:e.target.value
    })
  }

  render() {

    let formRef = this.state.anchorRef
    const TEXT_INPUT_FIELDS = [
      {
        label: "First Name",
        name: "first_name",
        require:false
      },
      {
        label: "Last Name",
        name: "last_name",
        require:false
      },
      {
        label: "Phone Number",
        name: "phone",
        require:false
      },
      {
        label: "Email",
        name: "email",
        require:true
      },
      {
        label: "Employer",
        name: "employer",
        require:false
      },
      {
        label: "Current Job / Trade",
        name: "job",
        require:false
      },
    ]
    const textInputFields = TEXT_INPUT_FIELDS.map(textInputField => {
      return (
        <TextField key={textInputField.name} margin="normal" fullWidth required={textInputField.require} label={textInputField.label} name={textInputField.name} onChange={this.handleOnChange}/>
      )
    })
    return (
      <Container className="payment-landing-page reserve-room-card reservation-summary" component="form" maxWidth="md" sx={{mt:8}} ref={formRef} noValidate>
        <Typography variant="h5" sx={{textAlign:'left', mb:2}} gutterBottom>
          Reservation Summary
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Paper elevation={3} sx={{p:2, mb:2}} >
              <Typography sx={{textAlign:'left', mt:2}}>
                Reservation Information
              </Typography>
              <Divider />
              <TextField margin="normal" fullWidth label='Arrival Date' value={this.state.arrive_at} inputProps={{readOnly:true}}/>
              <TextField margin="normal" fullWidth label='Departure Date' value={this.state.depart_at} inputProps={{readOnly:true}}/>
              <TextField margin="normal" fullWidth label='Guest Details' value={`${this.state.guest} (${this.state.guestCount})`} inputProps={{readOnly:true}}/>
            </Paper>
            <Paper elevation={3} sx={{p:2}} >
              <Typography sx={{textAlign:'left', mt:2}}>
                Guest Information
              </Typography>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Typography variant="body1" sx={{textAlign:'center', mt:2}}>
                  Thank you for your business! Please fill out this guest form to reserve a room. Payment information will be collected on the next page.
                </Typography>
                <Box  sx={{ mt: 1 }}>
                    {textInputFields}
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper elevation={3} sx={{p:2, mb:2}} >
              <Typography variant="h6" sx={{textAlign:'center', mt:2}}>
                {this.state.roomTypeName}
              </Typography>
              <div className='computation-div'>
                <p>
                  <span>&#36;</span>
                  {`${this.state.price} * ${this.state.numOfNights} ${this.state.numOfNights === 1 ? 'night' : 'nights'}`}
                </p>
                <p>
                  <span>&#36;</span>{this.state.price * this.state.numOfNights}
                </p>
              </div>
              <Divider/>
              <div className='total-div'>
                <p className='total-text'>Total</p>
                <p><span>&#36;</span>{this.state.price * this.state.numOfNights}</p>
              </div>
              <Button variant='contained' sx={{width:'100%', marginBottom:'10px', marginTop:'10px', height:'48px'}} onClick={() => this.handleSubmit(formRef.current.reportValidity())}>
                Checkout
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    )
  }
}

module.exports = withRouter(connect(mapStateToProps, mapDispatchToProps)(ReservationSummary));

