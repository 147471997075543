'use strict';

const React = require('react');
const Box = require('@mui/material/Box').default;
const Typography = require('@mui/material/Typography').default;
const Container = require('@mui/material/Container').default;
const Diversity1Icon = require('@mui/icons-material/Diversity1').default;
const Image = require('mui-image').default;
const Breadcrumbs = require('@mui/material/Breadcrumbs').default;
const Link = require('@mui/material/Link').default;

class ThankYouPage extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Container className="payment-landing-page" component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Breadcrumbs sx={{ pb: 2 }}>
            <Link underline="hover" color="inherit" href="/spring_haven">
              {`<Home`}
            </Link>
          </Breadcrumbs>
          <Diversity1Icon sx={{fontSize:'50px'}}/> 
          <Typography variant="h6" sx={{ textAlign:'center'}}>
            Thank you for booking with us!
          </Typography>
          <Image className="top-image" src="/spring-haven-motel.svg" alt="Spring Haven Motel" sx={{marginBottom:'20px', maxWidth:'200px'}}/>
          <Typography variant="body2" sx={{textAlign:'center'}}>
            Thank you for your reservation. If you have some question, feel free to get in touch.
          </Typography>
        </Box>
      </Container>
    )
  }
}

module.exports = ThankYouPage;