'use strict';
const React = require('react');
const Container = require('@mui/material/Container').default;
const Box = require('@mui/material/Box').default;
const Typography = require('@mui/material/Typography').default;
const TextField = require('@mui/material/TextField').default;
const Button = require('@mui/material/Button').default;
const Card = require('@mui/material/Card').default;
const CardContent = require('@mui/material/CardContent').default;
const Link = require('@mui/material/Link').default;
const Copyright = require('./Copyright');
const Alert = require('@mui/material/Alert').default;
const AlertTitle = require('@mui/material/AlertTitle').default;
const UsersActions = require('../actions/UsersActions');
const { withRouter } = require('react-router-dom');
const queryString = require('query-string').default;

class ResetPassword extends React.Component {
  constructor(props) {
    super(props)
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleResetPassword = this.handleResetPassword.bind(this);

    this.state = {
      email:'',
      hasWarning:false,
      message:'',
      severity:'',
      alertTitle:'',
      requestSent: false,
      reset_password_token:'',
      password1:'',
      password2:'',
      updateSuccessful:false,
      error:''
    }
  }

  componentDidMount() {
    if(this.props.location.search !== "") {
      let parsedData=queryString.parse(this.props.location.search)
      this.setState({
        reset_password_token:parsedData.token
      })
    }
  }

  handleOnChange(e) {
    this.setState({
      [e.target.name]:e.target.value
    })
  }

  handleSubmit() {
    let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if((this.state.email).match(validRegex)) {
      this.setState({
        requestSent:true
      })
      let params = {
        email: this.state.email
      }
      UsersActions.resetPassword(params)
    } else {
      this.setState({
        hasWarning:true,
      })
    }
  }

  handleResetPassword() {
    let params = {
      password1:this.state.password1,
      password2:this.state.password2,
      reset_password_token:this.state.reset_password_token
    }
    UsersActions.updatePassword(params).then(result => {
      if(!result.error) {
        this.setState({
          updateSuccessful:true
        })
      } else {
        this.setState({
          hasWarning:true,
          error:result.error
        })
      }
    })
  }

  render() {
    return(
      <Container className="payment-landing-page" component="main" maxWidth="xs">
        { !this.state.reset_password_token &&
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography component="h1" variant="h5">
              Password Reset
            </Typography>
            <Typography variant="body2" sx={{marginBottom:'10px', marginTop:'10px', textAlign:'center'}} gutterBottom>
              Enter your email to reset your password.
            </Typography>

              { !this.state.requestSent &&
                <Box sx={{width:'365px'}}>
                  <TextField key='email' margin="normal" fullWidth label='Email' name='email' type='email' onChange={this.handleOnChange}/>
                  { this.state.hasWarning &&
                    <Box sx={{width:'365px'}}>
                      <Alert severity='error' sx={{width:'auto'}}>
                        <AlertTitle>Invalid Email</AlertTitle>
                        <Typography>Please input a valid email address.</Typography>
                      </Alert>
                    </Box>
                  }
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{ mt: 1}}
                    onClick={this.handleSubmit}
                  >
                    SUBMIT
                  </Button>
                  <Button fullWidth sx={{ mt: 1}}>
                    <Link href='/login' sx={{textAlign:'center'}}>
                      BACK TO LOGIN PAGE
                    </Link>
                  </Button>
                </Box>
              }

              { this.state.requestSent &&
                <Box sx={{width:'365px'}}>
                  <Alert severity='success' sx={{width:'auto'}}>
                    <AlertTitle>Email Sent</AlertTitle>
                    <Typography>You should receive an email shortly with instructions to reset your password.</Typography>
                  </Alert>
                <Button fullWidth sx={{ mt: 1}}>
                  <Link href='/login' sx={{textAlign:'center'}}>
                    RETURN TO LOGIN PAGE
                  </Link>
                </Button>
                </Box>
              }
          </Box>
        }
        { this.state.reset_password_token &&
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {!this.state.updateSuccessful &&
              <div>
                <Typography component="h1" variant="h5">
                  Enter New Password
                </Typography>
                <TextField key='password1' margin="normal" fullWidth label='New Password' name='password1' type='password' onChange={this.handleOnChange}/>
                <TextField key='password2' margin="normal" fullWidth label='Retype New Password' name='password2' type='password' onChange={this.handleOnChange}/>
                { this.state.hasWarning &&
                  <Box sx={{width:'400px'}}>
                    <Alert severity='error' sx={{width:'auto'}}>
                      <AlertTitle>Error</AlertTitle>
                      <Typography>{this.state.error}</Typography>
                    </Alert>
                  </Box>
                }
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ mt: 1}}
                  onClick={this.handleResetPassword}
                >
                  SUBMIT
                </Button>
              </div>
            }
            {this.state.updateSuccessful && 
              <div>
                <Typography component="h1" variant="h5">
                  Finished
                </Typography>
                <Box sx={{width:'400px'}}>
                  <Alert severity='success' sx={{width:'auto'}}>
                    <AlertTitle>Success</AlertTitle>
                    <Typography>Your password was reset successfully! Please go back to the login page and login using your new password.</Typography>
                  </Alert>
                </Box>
                <Button fullWidth sx={{ mt: 1}}>
                  <Link href='/login' sx={{textAlign:'center'}}>
                    RETURN TO LOGIN PAGE
                  </Link>
                </Button>
              </div>
            }
          </Box>
        }
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    )
  }
}

module.exports = withRouter(ResetPassword);