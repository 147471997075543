'use strict';

const React = require('react');
const Divider = require('@mui/material/Divider').default;
const Typography = require('@mui/material/Typography').default;
const Box = require('@mui/material/Box').default;
const Button = require('@mui/material/Button').default;
const RoomsActions = require('../actions/RoomsActions');
const Container = require('@mui/material/Container').default;
const Breadcrumbs = require('@mui/material/Breadcrumbs').default;
const Link = require('@mui/material/Link').default;
const queryString = require('query-string').default;
const { connect } = require('react-redux');
const { dispatchLoad } = require('../helpers/DispatchHelpers');
const { withRouter } = require('react-router-dom');
const Grid = require('@mui/material/Grid').default;
const ImageCoverCarousel = require('./ImageCoverCarousel');
const ReserveRoomCard = require('./ReserveRoomCard');
const { ROOM_DATA } = require('../helpers/AvailableRoomHelper');
const { ROOM_CATEGORY } = require('../helpers/CheckAvailableRoomTypesHelpers');
const ReservationsActions = require('../actions/ReservationsActions');

const mapStateToProps = function (state, ownProps) {
  return {
    reservation: state.reservation
  }
}

const mapDispatchToProps = function (dispatch) {
  return {
    updateReservation: dispatchLoad(dispatch, 'reservation')
  }
}

class AvailableRoomType extends React.Component {
  constructor(props) {
    super(props)
    let parsedData=queryString.parse(this.props.location.search)
    
    this.state = {
      id:0,
      room_name:'',
      amenities:[],
      description:'',
      price:0,
      startDate: parsedData.arriveDate,
      endDate:parsedData.departureDate,
      guestCount:parsedData.guestCount,
      guestCategory:parsedData.guestCategory,
      displayMoreModal:false,
      coverPage:'',
      roomCategoryCode:'',
      availableRooms:[]
    }
  }

  componentDidMount() {
    let params  = {
      id:Number(this.props.match.params.id)
    }

    let result = ROOM_CATEGORY.find((obj) => obj.id === params.id)

    this.setState({
      id:result.id,
      room_name:result.room_name,
      amenities:result.amenities,
      description:result.description,
      price:result.price,
      coverPage:result.coverPage,
      roomCategoryCode:result.code,
    })
  }
  render() {
    let parse=queryString.parse(this.props.location.search)
    function params(data) {
      return Object.keys(data).map(key => `${key}=${encodeURIComponent(data[key])}`).join('&');
    }
    let newParams = params(parse)

    let amenities = this.state.amenities
    let amenitiesEl = amenities.map(item => {
      return(
        <div className='info-span' key={item.id}>
          <div className='info-icon'>
            {item.icon}
            <p className='title'>{item.title}</p>
          </div>
          <div className='info'>
            <p className='desc'>{item.description}</p>
          </div>
        </div>
      )
    })

    return (
      <Container className='available-room' maxWidth='md' sx={{m:'auto'}} component="main">
        <Box 
          sx={{
            marginTop: 8,
          }}
        >
          <Breadcrumbs sx={{ pb: 2 }}>
            <Link underline="hover" color="inherit" href={`/spring_haven/?${newParams}`}>
              Available Rooms
            </Link>
            <Typography color="primary">{this.state.id}</Typography>
          </Breadcrumbs>
          <Box className='box'>
            <ImageCoverCarousel 
              coverImages={this.state.coverPage}
            />
          </Box>  
          <Grid container spacing={2}>
            <Grid item xs={12} md={8} sx={{ padding:'10px'}}>
              <Box className='box'>
                <Typography  variant='h6' gutterBottom sx={{fontSize:'2em'}}>
                  {this.state.room_name}
                </Typography>
                <Typography variant='body1' gutterBottom sx={{fontStyle:'italic'}}>
                  {this.state.description}
                </Typography>
                <Divider/>
              </Box>
              <Box className='info-el box'>
                {amenitiesEl}
              </Box>
              <Divider/>
              <Box className='box'>
                <Typography>
                  {this.state.description}
                </Typography>
              </Box>
              <Divider/>
            </Grid>
            <Grid item  xs={12} md={4} >
              <ReserveRoomCard 
                price={this.state.price}
                arriveAt={this.state.startDate}
                departAt={this.state.endDate}
                guestCount={this.state.guestCount}
                guestCategory={this.state.guestCategory}
                roomId={this.state.id}
                roomCategoryCode={this.state.roomCategoryCode}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    )
  }
}

module.exports = withRouter(connect(mapStateToProps, mapDispatchToProps)(AvailableRoomType));