'use strict';

const React = require('react');
const Paper = require('@mui/material/Paper').default;
const MenuItem = require('@mui/material/MenuItem').default;
const Button = require('@mui/material/Button').default;
const ButtonGroup = require('@mui/material/ButtonGroup').default;
const ArrowDropDownIcon = require('@mui/icons-material/ArrowDropDown').default;
const ClickAwayListener = require('@mui/material/ClickAwayListener').default;
const Grow = require('@mui/material/Grow').default;
const Popper = require('@mui/material/Popper').default;
const MenuList = require('@mui/material/MenuList').default;
const IconButton = require('@mui/material/IconButton').default;
const AddCircleOutlineIcon = require('@mui/icons-material/AddCircleOutline').default;
const RemoveCircleOutlineIcon = require('@mui/icons-material/RemoveCircleOutline').default;
const { createTheme, ThemeProvider } = require('@mui/material/styles');
const SearchIcon = require('@mui/icons-material/Search').default;
const RefreshIcon = require('@mui/icons-material/Refresh').default;

const options = [
  'Adult'
]

const theme = createTheme({
  palette: {
    primary: {
      main:'#eef2f3'
    }
  },
});

function GuestCounterButton(props) {
  const {
    anchorRef, 
    open, 
    handleToggle,
    handleClose,
    addGuest,
    removeGuest,
    guestCategory,
    guestCount,
    previousPage,
    handleSearch,
    isReserveRoomCard
  } = props
  
  let disableAdd;
  let disableRemove;
  if(guestCount === 1) {
    disableAdd = true
  } 

  if(guestCount === 4) {
    disableRemove = true
  } 

  let searchRefreshIconButton;
    if(previousPage) {
      searchRefreshIconButton = 
        <IconButton 
          type="button" 
          sx={{ p: '10px', backgroundColor:'#1976d2', borderRadius:1, borderTopLeftRadius:'0px', borderBottomLeftRadius:'0px', height:'55px'}} 
          aria-label="search" 
          color='primary'
          href='/spring_haven'
        >
          <RefreshIcon />
        </IconButton>
    } else {
      searchRefreshIconButton = 
        <IconButton 
          type="button" 
          sx={{ p: '10px', backgroundColor:'#1976d2', borderRadius:1, borderTopLeftRadius:'0px', borderBottomLeftRadius:'0px', height:'55px'}} 
          aria-label="search" 
          color='primary'
          onClick={handleSearch}
          className={isReserveRoomCard ? 'search-button' : ''}
        >
          <SearchIcon />
        </IconButton>
    }

  return (
    <React.Fragment >
      <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button" sx={{boxShadow:'none'}} className={isReserveRoomCard ? 'button-group' : ''}>
        <Button sx={{width:'210px', display:'flex', justifyContent:'space-between'}} className={isReserveRoomCard ? 'button-dropdown' : ''}>{`${guestCount} ${guestCategory}`}
          <Button
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
            sx={{padding:'none'}}
          >
            <ArrowDropDownIcon />
          </Button>
        </Button>
        <ThemeProvider theme={theme}>
          {searchRefreshIconButton}
        </ThemeProvider>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem >
                  {options.map((option) => (
                    <MenuItem
                      key={option}
                      sx={{width:'250px'}}
                      className= {isReserveRoomCard ? 'add-remove-guest' : 'guest-category-dropdown' }
                    >
                      {option}
                      <div className='guest-counter'>
                        <IconButton disabled={disableAdd} onClick={removeGuest}>
                          <RemoveCircleOutlineIcon fontSize='large' />
                        </IconButton>
                          <p>{guestCount}</p>
                        <IconButton disabled={disableRemove} onClick={addGuest}>
                          <AddCircleOutlineIcon  fontSize='large' />
                        </IconButton>
                      </div>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}

module.exports = GuestCounterButton;