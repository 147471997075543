'use strict';
const Promise = require('bluebird');
const superagent = require('superagent');

const RoomsActions = {
  wherePublic: function (params = {}) {
    return new Promise(function (resolve, reject) {
      superagent.get('/public_api/rooms').query(params).end((err, res) => {
        if (err) { reject(err); }
        resolve(res.body.rooms);
      })
    })
  },
  where: function (params = {}) {
    return new Promise(function (resolve, reject) {
      superagent.get('/api/rooms').query(params).end((err, res) => {
        if (err) { reject(err); }
        resolve(res.body.rooms);
      })
    })
  },
  create: function (params) {
    return new Promise(function (resolve, reject) {
      superagent.post('/api/rooms')
        .send(params)
        .end((err, res) => {
          if (err) {
            resolve(err)
            return
          }
          resolve(res.body.room);
        })
    })
  },
  update: function (params) {
    return new Promise(function (resolve, reject) {
      superagent.put(`/api/rooms/${params.id}`)
        .send(params)
        .end((err, res) => {
          if (err) { reject(err); }
          resolve(res.body.room);
        })
    })
  },
  delete: function (params) {
    return new Promise(function (resolve, reject) {
      superagent.delete(`/api/rooms/${params.id}`)
        .send(params)
        .end((err, res) => {
          if (err) { reject(err); }
          resolve(res);
        })
    });
  }
};

module.exports = RoomsActions;