'use strict';

const ReservationReducer = function (state = {}, action) {
  switch (action.type) {
    case 'reservation.load':
      return action.reservation || {};
    default:
      return state;
  }
};

module.exports = ReservationReducer;