'use strict';
const Promise = require('bluebird');
const superagent = require('superagent');

const UsersActions = {
  updatePassword: function(params) {
    return new Promise(function (resolve, reject) {
      superagent.put(`/public_api/users/update_password`)
      .send(params)
      .end((err , res) => {
        if(err) { reject(err); }
        resolve(res.body)
      });
    });
  },
  resetPassword: function(params) {
    return new Promise(function (resolve, reject) {
      superagent.get(`/public_api/users/reset_password`)
        .query(params)
        .end((err, res) => {
          if(err) {reject(err)}
          resolve(res.body)
        })
    })
  },
  getActiveUser: function (params) {
    return new Promise(function (resolve, reject) {
      superagent.get(`/public_api/users/get_active_user`)
        .query(params)
        .end((err, res) => {
          if (err) { reject(err); }
          resolve(res.body.users);
        });
    });
  },
  login: function(params) {
    return new Promise(function (resolve, reject) {
      superagent.post(`/public_api/users/login`)
        .send(params)
        .end((err, res) => {
          if (err) { reject(err); }
          resolve(res.body);
        })
    })
  },
  logout: function(params) {
    return new Promise(function(resolve, reject) {
      superagent.post('/api/users/logout')
      .send(params)
      .end((err, res) => {
        if(err) {
          reject(err);
        }
        resolve(res.body);
      })
    })
  }
}

module.exports = UsersActions;