'use strict'
const React = require('react');
const { connect } = require('react-redux');
const stripe = Stripe(STRIPE_PUBLIC_KEY);
const Avatar = require('@mui/material/Avatar').default;
const Button = require('@mui/material/Button').default;
const CssBaseline = require('@mui/material/CssBaseline').default;
const TextField = require('@mui/material/TextField').default;
const FormControlLabel = require('@mui/material/FormControlLabel').default;
const Checkbox = require('@mui/material/Checkbox').default;
const Link = require('@mui/material/Link').default;
const Grid = require('@mui/material/Grid').default;
const Box = require('@mui/material/Box').default;
const Alert = require('@mui/material/Alert').default;
const LockOutlinedIcon = require('@mui/icons-material/LockOutlined').default;
const Copyright = require('./Copyright');
const Typography = require('@mui/material/Typography').default;
const Container = require('@mui/material/Container').default;
const Image = require('mui-image').default;
const StripeActions = require('../actions/StripeActions');
const NasEmailEventsActions = require('../actions/NasEmailEventsActions');
const GuestsActions = require('../actions/GuestsActions');
const queryString = require('query-string').default;
const { getPersonaName } = require('../helpers/GeneralHelpers');
const { EMAIL } = require('../server/config');
const UsersActions = require('../actions/UsersActions');

const mapStateToProps = function (state, ownProps) {
  return {
    user: state.user
  }
}

const mapDispatchToProps = function (dispatch) {
  return {
  }
}

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.updateForm = this.updateForm.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleDisplayAlert = this.toggleDisplayAlert.bind(this);

    this.state = {
      isLoading: false,
      email: '',
      password: '',
      displayAlert: false
    }

    if (this.props.user.id) {
      window.location.assign('/admin')
    }
  }

  updateForm(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({
      isLoading: true
    })

    var params = {
      username: this.state.email,
      password: this.state.password,
      redirect_to: '/admin'
    }

    UsersActions.login(params).then(login => {
      console.log('login', login)
      this.setState({
        isLoading: false
      })

      if (!login) {
        this.toggleDisplayAlert()
      } else {
        console.log('redirect')
        window.location.assign('/admin')
      }
    })
  };

  toggleDisplayAlert() {
    this.setState({
      displayAlert: !this.state.displayAlert
    })
  }

  render() {
    const TEXT_INPUT_FIELDS = [
      {
        label: "Email",
        key: "email"
      },
      {
        label: "Password",
        key: "password",
        type: "password"
      }
    ]
    const textInputFields = TEXT_INPUT_FIELDS.map(textInputField => {
      return (
        <TextField key={textInputField.key} margin="normal" fullWidth label={textInputField.label} name={textInputField.key} value={this.state.key} type={textInputField.type} onChange={this.updateForm} />
      )
    })
    return (
      <Container className="payment-landing-page" component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={this.handleSubmit} noValidate sx={{ mt: 1 }}>
            {textInputFields}



            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={this.state.isLoading}
            >
              LOGIN
            </Button>

            <Button fullWidth >
              <Link href='/reset_password' sx={{textAlign:'center'}}>
                Forgot Password
              </Link>
            </Button>

            {this.state.displayAlert &&
              <Alert onClose={this.toggleDisplayAlert} severity="error">Incorrect email or password</Alert>
            }
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    );
  }
}

module.exports = connect(mapStateToProps, mapDispatchToProps)(Login);