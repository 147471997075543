'use strict';
const { combineReducers } = require('redux');
const user = require('./user_reducer');
const room = require('./room_reducer');
const rooms = require('./rooms_reducer');
const reservation = require('./reservation_reducer');
const guest = require('./guest_reducer');

const RootReducer = combineReducers({
  user: user,
  room: room,
  rooms: rooms,
  reservation: reservation,
  guest: guest
});

module.exports = RootReducer;