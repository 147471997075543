'use strict';
const Promise = require('bluebird');

const DispatchHelpers = {
  dispatchLoad: function (dispatch, resourceName) {
    return function (keyedResourceCollection) {
      let action = { type: `${resourceName}.load` };
      action[resourceName] = keyedResourceCollection;
      return Promise.resolve(dispatch(action));
    };
  }
};

module.exports = DispatchHelpers;