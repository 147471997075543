'use strict';
const Promise = require('bluebird');
const superagent = require('superagent');

const GuestsActions = {
  where: function (params = {}) {
    return new Promise(function (resolve, reject) {
      superagent.get('/api/guests').query(params).end((err, res) => {
        if (err) { reject(err); }
        resolve(res.body.guests);
      })
    })
  },
  create: function (params) {
    return new Promise(function (resolve, reject) {
      superagent.post('/api/guests')
        .send(params)
        .end((err, res) => {
          if (err) {
            resolve(err)
            return
          }
          resolve(res.body.guest);
        })
    })
  },
  update: function (params) {
    return new Promise(function (resolve, reject) {
      superagent.put(`/api/guests/${params.id}`)
        .send(params)
        .end((err, res) => {
          if (err) { reject(err); }
          resolve(res.body.guest);
        })
    })
  },
  delete: function (params) {
    return new Promise(function (resolve, reject) {
      superagent.delete(`/api/guests/${params.id}`)
        .send(params)
        .end((err, res) => {
          if (err) { reject(err); }
          resolve(res);
        })
    });
  },
  search: function (params = {}) {
    return new Promise(function (resolve, reject) {
      superagent.get('/api/guests/search').query(params).end((err, res) => {
        if (err) { reject(err); }
        if (params.returnCountOnly) {
          resolve(res.body.count);
        } else {
          resolve(res.body.guests);
        }
      });
    });
  }
};

module.exports = GuestsActions;