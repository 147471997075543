'use strict';

const React = require('react');
const Image = require('mui-image').default;

let slideIndex = 1;

class ImageCoverCarousel extends React.Component {
  constructor(props) {
    super(props)
    this.showSlides = this.showSlides.bind(this);
    this.plusSlides = this.plusSlides.bind(this);
    this.currentSlide = this.currentSlide.bind(this);
  }

  showSlides(n) {
      let i;
      let slides = document.getElementsByClassName("mySlides");
      let dots = document.getElementsByClassName("dot");
      if (n > slides.length) {slideIndex = 1}    
      if (n < 1) {slideIndex = slides.length}
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";  
      }
      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
      }
      slides[slideIndex-1].style.display = "block";  
      dots[slideIndex-1].className += " active";
  }

  plusSlides(n) {
    this.showSlides(slideIndex += n);
  }

  currentSlide(n) {
    this.showSlides(slideIndex = n);
  }

  componentDidMount() {
    this.showSlides(slideIndex)
  }

  render() {
    let coverImages = this.props.coverImages
    let coverImagesArr = coverImages.split(',')
    
    let coverImageEl = coverImagesArr.map(image => {
      return (
        <div className="mySlides fade">
          <Image key={image} src={image} alt="Spring Haven Motel"/>
        </div>
      )
    }) 

    let dotEl = coverImagesArr.map((dot, index) => {
      return (
        <span className="dot" key={index} onClick={() => this.currentSlide(index + 1)}></span>
      )
    })
    return (
      <div>
        <div className="slideshow-container">
          {coverImageEl}
          <a className="prev" onClick={() => this.plusSlides(-1)}>❮</a>
          <a className="next" onClick={() => this.plusSlides(1)}>❯</a>
        </div>
        <br />
        <div className="dot-element" style={{textAlign:'center'}}>
          {dotEl}
        </div>
      </div>
    )
  }
}

module.exports = ImageCoverCarousel;