'use strict';

const React = require('react');
const Container = require('@mui/material/Container').default;
const Breadcrumbs = require('@mui/material/Breadcrumbs').default;
const Paper = require('@mui/material/Paper').default;
const Stack = require('@mui/material/Stack').default;
const TextField = require('@mui/material/TextField').default;
const { LocalizationProvider } = require('@mui/x-date-pickers/LocalizationProvider');
const { AdapterDayjs } = require('@mui/x-date-pickers/AdapterDayjs');
const { DatePicker } = require('@mui/x-date-pickers/DatePicker');
const IconButton = require('@mui/material/IconButton').default;
const SearchIcon = require('@mui/icons-material/Search').default;
const { createTheme, ThemeProvider } = require('@mui/material/styles');
const Box = require('@mui/material/Box').default;
const Image = require('mui-image').default;
const AvailableRoomTypes = require('./AvailableRoomTypes');
const Link = require('@mui/material/Link').default;
const { withRouter } = require('react-router-dom');
const queryString = require('query-string').default;
const RefreshIcon = require('@mui/icons-material/Refresh').default;
const ReservationsActions = require('../actions/ReservationsActions');
const Typography = require('@mui/material/Typography').default;
const GuestCounterButton = require('./GuestCounterButton');
const { ROOM_CATEGORY } = require('../helpers/CheckAvailableRoomTypesHelpers');



var todaysDate = new Date()
todaysDate.toISOString().split('T')[0]

var tomorrowsDate = new Date()
tomorrowsDate.setDate(todaysDate.getDate() + 1)

class CheckAvailableRoomTypes extends React.Component {
  constructor(props) {
    super(props) 
    this.myRef = React.createRef();
    this.handleStartDate = this.handleStartDate.bind(this);
    this.handleEndDate = this.handleEndDate.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.addGuest = this.addGuest.bind(this);
    this.removeGuest = this.removeGuest.bind(this);
    this.handleSearch = this.handleSearch.bind(this);

    this.state = {
      startDate:todaysDate,
      endDate:tomorrowsDate,
      open:false,
      anchorRef:this.myRef,
      guestCategory:'Adult',
      guestCount:1,
      hasAvailableRooms:false,
      roomTypes:[],
      previousPage:false,
      startSearch:false
    }
  }

  componentDidMount() {
    if(this.props.location.search !== "") {
      let parse=queryString.parse(this.props.location.search)
      function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
      }
      this.setState({
        startDate:parse.arriveDate,
        endDate:parse.departureDate,
        guestCategory:parse.guestCategory,
        guestCount:parse.guestCount
      })
      let params = {
        arrive_at:formatDate(parse.arriveDate),
        depart_at:formatDate(parse.departureDate)
      }

      ReservationsActions.checkAvailableRoomsTypes(params).then(result => {
        let roomTypes = []
        result.map(item => {
          for(const room of ROOM_CATEGORY) {
            if(item.identifier.includes (room.code)) {
              if(roomTypes.includes(room)) {
              } else {
                roomTypes.push(room)
              }
            }
          }
        })
        this.setState({
          roomTypes:roomTypes,
          hasAvailableRooms:result.length ? true : false,
          page:'Available Rooms',
          previousPage:true
        })
      })
    }
  }

  handleStartDate(newValue) {
    this.setState({
      startDate:newValue,
      roomTypes:[]
    })
  }

  handleEndDate(newValue) {
    this.setState({
      endDate:newValue,
      roomTypes:[]
    })
  }

  handleToggle() {
    this.setState({
      open:!this.state.open
    })
  }

  handleClose(e) {
    if(this.state.anchorRef.current && this.state.anchorRef.current.contains(e.target)) {
      return
    }
    this.setState({
      open:false
    })
  }

  addGuest() {
    this.setState({
      guestCount:this.state.guestCount + 1,
    })
  }

  removeGuest() {
    this.setState({
      guestCount:this.state.guestCount - 1,
    })
  }

  handleSearch() {
    function formatDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;
      return [year, month, day].join('-');
    }  
    let params = {
      arrive_at:formatDate(this.state.startDate),
      depart_at:formatDate(this.state.endDate)
    }
    ReservationsActions.checkAvailableRoomsTypes(params).then(result => {
      let roomTypes = []
      result.map(item => {
        for(const room of ROOM_CATEGORY) {
          if(item.identifier.includes (room.code)) {
            if(roomTypes.includes(room)) {
            } else {
              roomTypes.push(room)
            }
          }
        }
      })
      this.setState({
        roomTypes:roomTypes,
        startSearch:true,
        hasAvailableRooms:result.length ? true : false,
      })
    })
  }

  render () {
    let stateData = {
      arriveDate:this.state.startDate,
      departureDate:this.state.endDate,
      guestCategory:this.state.guestCategory,
      guestCount:this.state.guestCount
    };
    let content;
    if(this.state.hasAvailableRooms ) {
      content = <AvailableRoomTypes
        roomTypes={this.state.roomTypes}
        stateData={stateData}
      />
    } else {
      content =         
      <Container className="payment-landing-page" component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {(this.state.startSearch) ? 
            <div>
              <Typography 
                variant="subtitle1"
                gutterBottom 
                sx={{
                  fontStyle:'italic',
                  fontWeight:'bold',
                  textAlign:'center',
                  marginBottom:'20px',
                  marginTop:'-40px'
                }}
                >
                "Sorry, Spring Haven has no rooms available for the dates selected. Try to change the date to check for room availability."
              </Typography>
            </div>
            : 
            <div></div>
          }
        </Box>
      </Container>
    }
    return (
      <Container maxWidth='md' sx={{m:'auto'}} className='check-available-rooms' component="main">
        <Box 
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Breadcrumbs sx={{ pb: 2 }}>
            <Link underline="hover" color="inherit" href="/spring_haven">
              Available Rooms
            </Link>
          </Breadcrumbs>
          <Image className="top-image" src="/spring-haven-motel.svg" alt="Spring Haven Motel" sx={{marginBottom:'20px', maxWidth:'200px'}}/>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ md: 1 }}
            sx={{ mb: 3 }}
          >
            <Box
              className='box reserve-room-card'
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div className='date-picker'>
                  <DatePicker
                    disablePast
                    label="Arrive At"
                    value={this.state.startDate}
                    onChange={(newValue) => this.handleStartDate(newValue)}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </div>
                <div className='date-picker'>
                  <DatePicker
                    disablePast
                    label="Depart At"
                    value={this.state.endDate}
                    onChange={(newValue) => this.handleEndDate(newValue)}
                    renderInput={(params) => <TextField {...params} fullWidth  />}
                    
                  />
                </div>
                <GuestCounterButton
                  anchorRef={this.state.anchorRef}
                  open={this.state.open}
                  handleToggle={this.handleToggle}
                  handleClose={this.handleClose}
                  addGuest={this.addGuest}
                  removeGuest={this.removeGuest}
                  guestCategory={this.state.guestCategory}
                  guestCount={this.state.guestCount}
                  previousPage={this.state.previousPage}
                  handleSearch={this.handleSearch}
                />
              </LocalizationProvider>
            </Box>
          </Stack>
        </Box>
        {content}
      </Container>
    )
  }
}

module.exports = withRouter(CheckAvailableRoomTypes);
