'use strict';

const GuestReducer = function (state = {}, action) {
  switch (action.type) {
    case 'guest.load':
      return action.guest || {};
    default:
      return state;
  }
};

module.exports = GuestReducer;