'use strict'

const React = require('react');
const Divider = require('@mui/material/Divider').default;
const Grid = require('@mui/material/Grid').default;
const Paper = require('@mui/material/Paper').default;
const Typography = require('@mui/material/Typography').default;
const Image = require('mui-image').default;
const Button = require('@mui/material/Button').default;
const { withRouter } = require('react-router-dom');
class AvailableRoomTypes extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      roomTypes:this.props.roomTypes
    }
  }

  render() {   
    function params(data) {
      return Object.keys(data).map(key => `${key}=${encodeURIComponent(data[key])}`).join('&');
    }
    let newParams = params(this.props.stateData)  
    return (
      <div>
        { this.state.roomTypes.map((item) => (
          <Paper sx={{p:3, mt:1, display:'flex'}} elevation={3} key={item.id}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Image className="top-image" src="spring-haven-motel.svg" alt="Spring Haven Motel" sx={{maxWidth:'300px'}} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant='h6'>
                  {item.room_name}
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  <span>&#36;</span>{item.price}
                </Typography>
                <Typography 
                  variant="subtitle2" 
                  gutterBottom 
                  sx={{
                    textAlign:'justify',
                    lineHeight:'24px',
                    overflow:'hidden',
                    textOverflow:'ellipsis',
                    display:'-webkit-box',
                    WebkitLineClamp:'6',
                    WebkitBoxOrient:'vertical'
                  }}
                  >
                  {item.description}
                </Typography>
                <Button 
                  variant='contained'
                  href={`/spring_haven/rooms/${item.id}/?${newParams}`}
                >
                  View
                </Button>
              </Grid>
            </Grid>
          </Paper>
        ))}
      </div>
    )
  }
}

module.exports = withRouter(AvailableRoomTypes);