var React = require('react');
var { createRoot } = require('react-dom/client');
const { BrowserRouter, Route, Switch } = require('react-router-dom');
import "../../css/app-bundle.scss";
const { createStore } = require('redux');
const ReactRedux = require('react-redux');
const RootReducer = require('../reducers/root_reducer');
var PaymentLandingPage = require('../components/PaymentLandingPage');
const { createTheme, ThemeProvider } = require('@mui/material/styles');
const theme = createTheme();
const { LocalizationProvider } = require('@mui/x-date-pickers/LocalizationProvider');
const { AdapterDayjs } = require('@mui/x-date-pickers/AdapterDayjs');

const LoginPage = require('../components/LoginPage');
const ClientLayout = require('../components/ClientLayout');
const ResetPasswordPage = require('../components/ResetPasswordPage');

const store = createStore(RootReducer);

class AppBundle extends React.Component {
  render() {
    return (
      <ReactRedux.Provider store={store}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <BrowserRouter>
              <Switch>
                <Route path="/spring_haven" component={ClientLayout} />
                <Route path="/reset_password" render={() => <ResetPasswordPage/>} />
                <Route path="/login" render={() => <LoginPage/>} />
                <Route path="/" component={PaymentLandingPage} />
              </Switch>
            </BrowserRouter>
          </LocalizationProvider>
        </ThemeProvider>
      </ReactRedux.Provider>
      
    );
  }
}

const root = createRoot(document.getElementById('root'))
root.render(<AppBundle />);