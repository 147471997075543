'use strict';

const RoomReducer = function (state = {}, action) {
  switch (action.type) {
    case 'room.load':
      return action.room || {};
    default:
      return state;
  }
};

module.exports = RoomReducer;