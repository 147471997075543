'use strict';

const React = require('react');
const Typography = require('@mui/material/Typography').default;
const Box = require('@mui/material/Box').default;
const Button = require('@mui/material/Button').default;
const { styled } = require('@mui/material/styles');
const Paper = require('@mui/material/Paper').default;
const { LocalizationProvider } = require('@mui/x-date-pickers/LocalizationProvider');
const { AdapterDayjs } = require('@mui/x-date-pickers/AdapterDayjs');
const { DatePicker } = require('@mui/x-date-pickers/DatePicker');
const TextField = require('@mui/material/TextField').default;
const Divider = require('@mui/material/Divider').default;
const GuestCounterButton = require('./GuestCounterButton');
const ReservationsActions = require('../actions/ReservationsActions');
const RoomsActions = require('../actions/RoomsActions');
const { dispatchLoad } = require('../helpers/DispatchHelpers');
const { connect } = require('react-redux');
const { withRouter } = require('react-router-dom');

const mapStateToProps = function (state, ownProps) {
  return {
    reservation: state.reservation
  }
}

const mapDispatchToProps = function (dispatch) {
  return {
    updateReservation: dispatchLoad(dispatch, 'reservation')
  }
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

var todaysDate = new Date()
todaysDate.toISOString().split('T')[0]

var tomorrowsDate = new Date()
tomorrowsDate.setDate(todaysDate.getDate() + 1)

class ReserveRoomCard extends React.Component  {
  constructor(props) {
    super(props)
    this.myRef = React.createRef();
    this.handleStartDate = this.handleStartDate.bind(this);
    this.handleEndDate = this.handleEndDate.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.addGuest = this.addGuest.bind(this);
    this.removeGuest = this.removeGuest.bind(this);
    this.checkRoomAvailability = this.checkRoomAvailability.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      arrive_at:this.props.arriveAt,
      depart_at:this.props.departAt,
      open:false,
      anchorRef:this.myRef,
      guestCategory:this.props.guestCategory,
      guestCount:Number(this.props.guestCount),
      price:Number(this.props.price),
      isRoomAvailable:true,
      roomId:this.props.match.params.id,
      displayText:'',
    }
  }

  handleStartDate(newValue) {
    this.setState({
      arrive_at:newValue,
      isRoomAvailable:false,
      displayText:'',
      availableRooms:[]
    })
  }

  handleEndDate(newValue) {
    this.setState({
      depart_at:newValue,
      isRoomAvailable:false,
      displayText:'',
      availableRooms:[]
    })
  }

  handleToggle() {
    this.setState({
      open:!this.state.open
    })
  }

  handleClose(e) {
    if(this.state.anchorRef.current && this.state.anchorRef.current.contains(e.target)) {
      return
    }
    this.setState({
      open:false
    })
  }

  addGuest() {
    this.setState({
      guestCount:this.state.guestCount + 1,
    })
  }

  removeGuest() {
    this.setState({
      guestCount:this.state.guestCount - 1,
    })
  }

  checkRoomAvailability() {
    function formatDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;
      return [year, month, day].join('-');
    }
    let params = {
      identifier:this.props.roomCategoryCode
    }
    // fetch rooms that has the room_code_category
    RoomsActions.wherePublic(params).then(result => {
      result.map(item => {
        let params = {
          room_id:item.id,
          arrive_at:formatDate(this.state.arrive_at),
          depart_at:formatDate(this.state.depart_at),
          useRangeFilter: true
        }
        // check if room has no reservation
        ReservationsActions.checkRoomAvailability(params).then(result => {
          if(result.length === 0) {
            // will be  added to the list of available rooms
            this.setState({
              availableRooms:[...this.state.availableRooms, item],
              isRoomAvailable:true,
              displayText:''
            })
          } else {
            this.setState({
              isRoomAvailable:false,
              displayText:'Sorry room is not available on the selected dates. Try to change dates to check room availability.'
            })
          }
        })
      })
    })
  }

  handleSubmit() {
    let reservation = {
      arrive_at:this.state.arrive_at,
      depart_at:this.state.depart_at,
      guestCategory:this.state.guestCategory,
      guestCount:this.state.guestCount,
    }
    this.props.updateReservation(reservation)
    this.props.history.push(`/spring_haven/${this.state.roomId}/reservation_summary`);
  }

  render() {
    let arrive_at = new Date(this.state.arrive_at);
    let depart_at = new Date(this.state.depart_at);
    let timeDiff = Math.abs(depart_at.getTime() - arrive_at.getTime());
    let numOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24));

    let textDisplay;
    if(this.state.displayText) {
      textDisplay =
      <Typography variant="caption" display="block" sx={{fontStyle:'italic', marginTop:'5px'}}>{`${this.state.displayText}`}</Typography>
    } 

    let reserveOrCheckRoomButton;
    if(this.state.isRoomAvailable) {
      reserveOrCheckRoomButton = 
      <div>
        <Button  variant='contained' onClick={this.handleSubmit} sx={{width:'100%', marginBottom:'10px', marginTop:'10px', height:'48px'}}>
          Reserve Room
        </Button>
      </div>
    } else {
      reserveOrCheckRoomButton = 
      <div>
        <Button  variant='contained' onClick={this.checkRoomAvailability} sx={{width:'100%', marginBottom:'10px', marginTop:'10px', height:'48px'}}>
          Check Room Availability
        </Button>
      </div>
    }

    return (
      <Box 
      className='box reserve-room-card'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
        <Item>
          <Typography variant='subtitle2' gutterBottom sx={{textAlign:'left', fontWeight:800, fontSize:'20px',
        lineHeight:2.57}}>
            <span>&#36;</span>{this.props.price} / night
          </Typography>
          <div className='date-picker'>
            <LocalizationProvider dateAdapter={AdapterDayjs} >
              <DatePicker
                disablePast
                label="Arrive At"
                value={this.state.arrive_at}
                onChange={(newValue) => this.handleStartDate(newValue)}
                renderInput={(params) => <TextField {...params}/>}
              />
              <DatePicker
                disablePast
                label="Depart At"
                value={this.state.depart_at}
                onChange={(newValue) => this.handleEndDate(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
          <div className='guest-counter-reserve-room-card'>
            <GuestCounterButton
              anchorRef={this.state.anchorRef}
              open={this.state.open}
              handleToggle={this.handleToggle}
              handleClose={this.handleClose}
              addGuest={this.addGuest}
              removeGuest={this.removeGuest}
              guestCategory={this.state.guestCategory}
              guestCount={this.state.guestCount}
              isReserveRoomCard={true}
            />
          </div>
          {textDisplay}
          {reserveOrCheckRoomButton}
          <Typography >
            You won't be charged yet
          </Typography>
          <div className='computation-div'>
            <p>
              <span>&#36;</span>
              {`${this.props.price} * ${numOfNights} ${numOfNights === 1 ? 'night' : 'nights'}`}
            </p>
            <p>
              <span>&#36;</span>{this.props.price * numOfNights}
            </p>
          </div>
          <Divider/>
          <div className='total-div'>
            <p className='total-text'>Total</p>
            <p><span>&#36;</span>{this.props.price * numOfNights}</p>
          </div>
        </Item>
      </Box>
    )
  }
}

module.exports = withRouter(connect(mapStateToProps, mapDispatchToProps)(ReserveRoomCard));
