'use strict'
var React = require('react');
const DoorFrontOutlinedIcon = require('@mui/icons-material/DoorFrontOutlined').default;
const room_code_category = [
  {
    id:1,
    room_name:'Motel Room',
    description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas orci libero, tempus sed faucibus vitae, blandit nec enim. Fusce ac volutpat elit, in faucibus diam.',
    price:400,
    code:'spring_haven_motel_room_double',
    coverPage:'https://www.gannett-cdn.com/-mm-/05b227ad5b8ad4e9dcb53af4f31d7fbdb7fa901b/c=0-64-2119-1259/local/-/media/USATODAY/USATODAY/2014/08/13/1407953244000-177513283.jpg,https://images.unsplash.com/photo-1566073771259-6a8506099945?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8aG90ZWxzfGVufDB8fDB8fA%3D%3D&w=1000&q=80,https://thumbs.dreamstime.com/b/hotel-room-13225019.jpg,https://ucarecdn.com/4f78bee9-11f0-4926-b1cf-f2709191da4b/,https://static01.nyt.com/images/2019/03/24/travel/24trending-shophotels1/24trending-shophotels1-videoSixteenByNineJumbo1600.jpg',
    amenities:[
      {
        id:1,
        icon:<DoorFrontOutlinedIcon/>,
        title:'Two Beds',
        description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas orci libero, tempus sed faucibus vitae, blandit nec enim. Fusce ac volutpat elit, in faucibus diam. '
      }
    ]
  },
  {
    id:2,
    room_name:'Motel Room',
    description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas orci libero, tempus sed faucibus vitae, blandit nec enim. Fusce ac volutpat elit, in faucibus diam.',
    price:350,
    code:'spring_haven_motel_room_single',
    coverPage:'https://www.gannett-cdn.com/-mm-/05b227ad5b8ad4e9dcb53af4f31d7fbdb7fa901b/c=0-64-2119-1259/local/-/media/USATODAY/USATODAY/2014/08/13/1407953244000-177513283.jpg,https://images.unsplash.com/photo-1566073771259-6a8506099945?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8aG90ZWxzfGVufDB8fDB8fA%3D%3D&w=1000&q=80,https://thumbs.dreamstime.com/b/hotel-room-13225019.jpg,https://ucarecdn.com/4f78bee9-11f0-4926-b1cf-f2709191da4b/,https://static01.nyt.com/images/2019/03/24/travel/24trending-shophotels1/24trending-shophotels1-videoSixteenByNineJumbo1600.jpg',
    amenities:[
      {
        id:1,
        icon:<DoorFrontOutlinedIcon/>,
        title:'Single Bed',
        description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas orci libero, tempus sed faucibus vitae, blandit nec enim. Fusce ac volutpat elit, in faucibus diam. '
      }
    ]
  },
  {
    id:3,
    room_name:'Apartment Unit',
    description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas orci libero, tempus sed faucibus vitae, blandit nec enim. Fusce ac volutpat elit, in faucibus diam.',
    price:450,
    code:'spring_haven_motel_apartment',
    coverPage:'https://www.gannett-cdn.com/-mm-/05b227ad5b8ad4e9dcb53af4f31d7fbdb7fa901b/c=0-64-2119-1259/local/-/media/USATODAY/USATODAY/2014/08/13/1407953244000-177513283.jpg,https://images.unsplash.com/photo-1566073771259-6a8506099945?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8aG90ZWxzfGVufDB8fDB8fA%3D%3D&w=1000&q=80,https://thumbs.dreamstime.com/b/hotel-room-13225019.jpg,https://ucarecdn.com/4f78bee9-11f0-4926-b1cf-f2709191da4b/,https://static01.nyt.com/images/2019/03/24/travel/24trending-shophotels1/24trending-shophotels1-videoSixteenByNineJumbo1600.jpg',
    amenities:[
      {
        id:1,
        icon:<DoorFrontOutlinedIcon/>,
        title:'Apartment Type',
        description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas orci libero, tempus sed faucibus vitae, blandit nec enim. Fusce ac volutpat elit, in faucibus diam. '
      },
      {
        id:2,
        icon:<DoorFrontOutlinedIcon/>,
        title:'Two Bedrooms',
        description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas orci libero, tempus sed faucibus vitae, blandit nec enim. Fusce ac volutpat elit, in faucibus diam. '
      }
    ]
  },
  {
    id:4,
    room_name:'Spring Haven Cottage',
    description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas orci libero, tempus sed faucibus vitae, blandit nec enim. Fusce ac volutpat elit, in faucibus diam.',
    price:400,
    code:'spring_haven_cottage',
    coverPage:'https://www.gannett-cdn.com/-mm-/05b227ad5b8ad4e9dcb53af4f31d7fbdb7fa901b/c=0-64-2119-1259/local/-/media/USATODAY/USATODAY/2014/08/13/1407953244000-177513283.jpg,https://images.unsplash.com/photo-1566073771259-6a8506099945?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8aG90ZWxzfGVufDB8fDB8fA%3D%3D&w=1000&q=80,https://thumbs.dreamstime.com/b/hotel-room-13225019.jpg,https://ucarecdn.com/4f78bee9-11f0-4926-b1cf-f2709191da4b/,https://static01.nyt.com/images/2019/03/24/travel/24trending-shophotels1/24trending-shophotels1-videoSixteenByNineJumbo1600.jpg',
    amenities:[
      {
        id:1,
        icon:<DoorFrontOutlinedIcon/>,
        title:'Shared Cottage',
        description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas orci libero, tempus sed faucibus vitae, blandit nec enim. Fusce ac volutpat elit, in faucibus diam. '
      },
      {
        id:2,
        icon:<DoorFrontOutlinedIcon/>,
        title:'Private Bedroom (2 rooms)',
        description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas orci libero, tempus sed faucibus vitae, blandit nec enim. Fusce ac volutpat elit, in faucibus diam. '
      }
    ],
  },
  {
    id:5,
    room_name:'Lehigh Cottage',
    description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas orci libero, tempus sed faucibus vitae, blandit nec enim. Fusce ac volutpat elit, in faucibus diam.',
    price:400,
    code:'lehigh_cottage',
    coverPage:'https://www.gannett-cdn.com/-mm-/05b227ad5b8ad4e9dcb53af4f31d7fbdb7fa901b/c=0-64-2119-1259/local/-/media/USATODAY/USATODAY/2014/08/13/1407953244000-177513283.jpg,https://images.unsplash.com/photo-1566073771259-6a8506099945?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8aG90ZWxzfGVufDB8fDB8fA%3D%3D&w=1000&q=80,https://thumbs.dreamstime.com/b/hotel-room-13225019.jpg,https://ucarecdn.com/4f78bee9-11f0-4926-b1cf-f2709191da4b/,https://static01.nyt.com/images/2019/03/24/travel/24trending-shophotels1/24trending-shophotels1-videoSixteenByNineJumbo1600.jpg',
    amenities:[
      {
        id:1,
        icon:<DoorFrontOutlinedIcon/>,
        title:'Shared Cottage',
        description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas orci libero, tempus sed faucibus vitae, blandit nec enim. Fusce ac volutpat elit, in faucibus diam. '
      },
      {
        id:2,
        icon:<DoorFrontOutlinedIcon/>,
        title:'Private Bedroom (2 rooms)',
        description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas orci libero, tempus sed faucibus vitae, blandit nec enim. Fusce ac volutpat elit, in faucibus diam. '
      }
    ],
  },
]

const CheckAvailableRoomTypesHelpers = {
  ROOM_CATEGORY:room_code_category
}

module.exports = CheckAvailableRoomTypesHelpers;