'use strict';

const GeneralHelpers = {
  getPersonaName: function (personaIn, options = {}) {
    var nameOut = ''
    if (!personaIn) return nameOut;
    var firstNameKey = options.firstNameKey || 'first_name'
    var lastNameKey = options.lastNameKey || 'last_name'
    if (personaIn[firstNameKey] || personaIn[lastNameKey]) {
      nameOut = [personaIn[firstNameKey].trim() || '', personaIn[lastNameKey].trim() || ''].join(' ').trim()
    } else if (personaIn.email) {
      nameOut = personaIn.email
    }

    if (options.initialsOnly) {
      return nameOut.split(' ').map(s => s[0]).join(" ")
    } else {
      return nameOut
    }
  },
  sortArrayByObjectProperty(arrayIn, objectProperty, reverse, options = {}) {
    if (options.includeAllElements) {
      for (var i = 0; i < arrayIn.length; i++) {
        if (!arrayIn[i][objectProperty]) {
          arrayIn[i][objectProperty] = ''
        }
      }
    }

    function compare(a, b) {
      if (a[objectProperty] < b[objectProperty])
        if (reverse) {
          return 1;
        } else {
          return -1;
        }

      if (a[objectProperty] > b[objectProperty])
        if (reverse) {
          return -1;
        } else {
          return 1;
        }
      return 0;
    }
    return arrayIn.sort(compare);
  },
  amountToDollarString: function (amount, options={}) {
    var dollars;
    if (options.isDollarAmount) {
      amount = amount * 100
    }
    
    if (amount % 100 !== 0) {
      dollars = (amount / 100).toFixed(2)
    } else {
      dollars = amount / 100
      if (options.includeDecimals) {
        dollars = dollars.toFixed(2);
      }
    }
    return `$${dollars.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
  },
  getDateString: function (yyyymmdd, options={}) {
    var sliceStart = 0;
    if (options.monthAndDateOnly) {
      sliceStart = 1
    }
    return (new Date(yyyymmdd + 'T12:00+05:00')).toDateString().split(' ').slice(sliceStart, 3).join(' ')
  },
  getFullWeeksStartAndEndInMonth: function (monthBase1, year) {
    let month = monthBase1 - 1;
    let _start = 0; // Always start on Sunday
    let weeks = [],
      firstDate = new Date(year, month, 1),
      lastDate = new Date(year, month + 1, 0),
      numDays = lastDate.getDate()

    let start = 1
    let end = 7 - firstDate.getDay()
    let startMonth = month;
    let startYear = year;
    let endMonth = month;
    let endYear = year;
    let number = 1
    if (firstDate.getDay() === _start) {
      end = 7
    } else {
      let preMonthEndDay = new Date(year, month, 0)
      startMonth = preMonthEndDay.getMonth()
      startYear = preMonthEndDay.getFullYear()
      start = preMonthEndDay.getDate() + 1 - firstDate.getDay() + _start
      end = 7 - firstDate.getDay() + _start
      weeks.push({
        start: `${startYear}-${("0" + (startMonth + 1)).slice(-2)}-${("0" + start).slice(-2)}`,
        end: `${endYear}-${("0" + (endMonth + 1)).slice(-2)}-${("0" + end).slice(-2)}`,
        number: number
      })
      start = end + 1
      end = end + 7
      number++
    }
    while (start <= numDays) {
      startMonth = month
      startYear = year
      endMonth = month
      endYear = year
      weeks.push({
        start: `${startYear}-${("0" + (startMonth + 1)).slice(-2)}-${("0" + start).slice(-2)}`,
        end: `${endYear}-${("0" + (endMonth + 1)).slice(-2)}-${("0" + end).slice(-2)}`,
        number: number
      })
      start = end + 1
      end = end + 7
      number++

      if (end > numDays) {
        end = end - numDays + _start
        startMonth = month
        startYear = year
        endMonth = (month + 1) % 12
        endYear = endMonth < month ? year + 1 : year
        weeks.push({
          start: `${startYear}-${("0" + (startMonth + 1)).slice(-2)}-${("0" + start).slice(-2)}`,
          end: `${endYear}-${("0" + (endMonth + 1)).slice(-2)}-${("0" + end).slice(-2)}`,
          number: number
        })
        break
      }
    }
    return weeks
  }
}

module.exports = GeneralHelpers;